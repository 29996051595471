<template>
  <div class="mt-4">
    <h3>{{ $t("item.browse") }}</h3>
    <VueSlickCarousel
      :rtl="$i18n.locale == 'ar' ? true : false"
      :slidesPerRow="$vuetify.breakpoint.width < 960  ? 2 : 5"
      :infinite="false"
    >
      <template #prevArrow="arrowOption">
        <v-btn
          class="grey -darken-4"
          icon
          :disabled="arrowOption.currentSlide == 0"
        >
          <v-icon color="white" dark>{{$t('swipe.prevArrowIcon')}}</v-icon></v-btn
        >
      </template>
      <template #nextArrow="arrowOption">
        <v-btn
          :disabled="arrowOption.currentSlide == arrowOption.slideCount - 1"
          icon
          class="grey -darken-4"
        >
          <v-icon color="white">{{$t('swipe.nextArrowIcon')}}</v-icon></v-btn
        >
      </template>
      <div class="pa-1" v-for="item in items" :key="item.id">
        <item-card class="pa-1" :item="item" />
      </div>
    </VueSlickCarousel>
  </div>
</template>


<script>
import VueSlickCarousel from "vue-slick-carousel-rtl";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css";
// optional style for arrows & dots
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    ItemCard: () => import("./ItemCard.vue"),
    VueSlickCarousel,
  },
  props: ["items"],
};
</script>